import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import Select from 'react-dropdown-select';
import moment from 'moment';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import AppConstants from '../../appConstants';
import close from '../../adminAssets/images/dashbaord_X.svg';
import webServices from '../../services/webServices';

const AdminSidebarFilter = forwardRef((props, ref) => {
  const SALARY_MIN_VALUE = 0;
  const SALARY_MAX_VALUE = 500;
  const SALARY_RANGE_STEP_VALUE = 10;
  const [rangeValue, setRangeValue] = useState({ min: SALARY_MIN_VALUE, max: SALARY_MAX_VALUE });
  const [masterData, setMasterData] = useState({});

  const DEFAULT_FORM_VALUES = {
    name: '',
    email: '',
    phone_no: '',
    country: '',
    state: '',
    city: '',
    educationLevel: '',
    originCountry: '',
    originState: '',
    originCity: '',
    registrationDate: '',
    userId: '',
    hasPhoto: '',
    hasPhoneNo: '',
    hasLinkedinUrl: '',
    hasAboutMe: '',
    hasLookingFor: '',
    hasActiveSubscription: '',
    salaryRange: { min: rangeValue.min, max: rangeValue.max },
  };

  const [filterFormData, setFilterFormData] = useState(DEFAULT_FORM_VALUES);

  const localRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const { history, searchUsersCallbackHandler } = props;
  if (!localStorage.getItem('lovPermitAdmin')) {
    history.push(AppConstants.routes.home);
  }

  useEffect(async () => {
    const resp = await webServices.getCommonMasterData();
    if (resp && resp.success) {
      setMasterData(resp.data);
    }
  }, []);

  const toggleVisibility = () => {
    setIsVisible(prevValue => !prevValue);
  };

  const closeButtonHandler = () => {
    toggleVisibility();
  }

  const resetButtonHandler = () => {
    setFilterFormData(DEFAULT_FORM_VALUES);
    toggleVisibility();

    window.scrollTo(0, 0);
    searchUsersCallbackHandler(DEFAULT_FORM_VALUES);
  }

  const applyButtonHandler = () => {
    toggleVisibility();
    window.scrollTo(0, 0);
    searchUsersCallbackHandler(filterFormData);
  }

  const rangeUpdatedHandler = salaryRange => {
    setFilterFormData(prevFormData => ({
      ...prevFormData,
      salaryRange
    }));
  };

  const inputChangeHandler = evt => {
    const { name, value } = evt.target;

    setFilterFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const selectChangeHandler = ({name, value}) => {
    setFilterFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  useImperativeHandle(ref, () => ({
    toggleVisibilityMethod() {
      toggleVisibility();
    },
    getElement() {
      return localRef.current;
    }
  }));

  return (
    <>
      <div ref={localRef} className={`filter_menu open_filter ${isVisible ? '' : 'd-none'}`}>
        <div className="menu-wrapper">
          <div className='close_filter'>
            <button type='button' className='btn btn-close' onClick={closeButtonHandler}>
              <img title="close" src={close} alt="close" />
            </button>
          </div>
          <h2>Filter by:</h2>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" name='name' value={filterFormData.name} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by name" id="name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" name='email' value={filterFormData.email} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by email id" id="email" />
          </div>
          <div className="form-group">
            <label htmlFor="phone_no">Phone Number</label>
            <input type="number" name='phone_no' value={filterFormData.phone_no} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by phone number" id="phone_no" />
          </div>
          <div className="form-group">
            <label htmlFor="country">Residence Country</label>
            <input type="text" name='country' value={filterFormData.country} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by residence country" id="country" />
          </div>
          <div className="form-group">
            <label htmlFor="state">Residence State</label>
            <input type="text" name='state' value={filterFormData.state} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by residence state" id="state" />
          </div>
          <div className="form-group">
            <label htmlFor="city">Residence City</label>
            <input type="text" name='city' value={filterFormData.city} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by residence city" id="city" />
          </div>
          <div className="form-group">
            <label htmlFor="educationLevel">Level of Education</label>
            <Select
              placeholder="Level of Education"
              options={masterData?.degree}
              autoComplete="off"
              className="custom_input"
              values={filterFormData.educationLevel ? filterFormData.educationLevel : []}
              onChange={val => selectChangeHandler({name: 'educationLevel', value: val[0].value})}
            />
          </div>

          <hr className='mt-4 mb-0' />

          <div className="form-group">
            <label htmlFor="originCountry">Origin Country</label>
            <input type="text" name='originCountry' value={filterFormData.originCountry} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by origin country" id="originCountry" />
          </div>
          <div className="form-group">
            <label htmlFor="originState">Origin State</label>
            <input type="text" name='originState' value={filterFormData.originState} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by origin state" id="originState" />
          </div>
          <div className="form-group">
            <label htmlFor="originCity">Origin City</label>
            <input type="text" name='originCity' value={filterFormData.originCity} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by origin city" id="originCity" />
          </div>

          <hr className='mt-4 mb-0' />

          <div className="form-group">
            <label htmlFor="registrationDate">Registration Date</label>
            <input type="date" max={moment().format('YYYY-MM-DD')} name='registrationDate' value={filterFormData.registrationDate} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search user by origin city" id="registrationDate" />
          </div>
          <div className="form-group">
            <label htmlFor="userId">User ID</label>
            <input type="text" name='userId' value={filterFormData.userId} onChange={inputChangeHandler} className="form-control inpt" placeholder="Search by user id" id="userId" />
          </div>

          <hr className='mt-4 mb-0' />

          <div className="form-group">
            <label htmlFor="hasPhoto">Has Photo</label>
            <div className='row'>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasPhoto" value="" checked={filterFormData.hasPhoto === ''} onChange={inputChangeHandler} /> All
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasPhoto" value="1" checked={filterFormData.hasPhoto === '1'} onChange={inputChangeHandler} /> Yes
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasPhoto" value="0" checked={filterFormData.hasPhoto === '0'} onChange={inputChangeHandler} /> No
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="hasPhoneNo">Has Phone #</label>
            <div className='row'>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasPhoneNo" value="" checked={filterFormData.hasPhoneNo === ''} onChange={inputChangeHandler} /> All
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasPhoneNo" value="1" checked={filterFormData.hasPhoneNo === '1'} onChange={inputChangeHandler} /> Yes
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasPhoneNo" value="0" checked={filterFormData.hasPhoneNo === '0'} onChange={inputChangeHandler} /> No
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="hasLinkedinUrl">Has Linkedin Url</label>
            <div className='row'>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasLinkedinUrl" value="" checked={filterFormData.hasLinkedinUrl === ''} onChange={inputChangeHandler} /> All
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasLinkedinUrl" value="1" checked={filterFormData.hasLinkedinUrl === '1'} onChange={inputChangeHandler} /> Yes
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasLinkedinUrl" value="0" checked={filterFormData.hasLinkedinUrl === '0'} onChange={inputChangeHandler} /> No
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="hasAboutMe">Has About Me</label>
            <div className='row'>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasAboutMe" value="" checked={filterFormData.hasAboutMe === ''} onChange={inputChangeHandler} /> All
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasAboutMe" value="1" checked={filterFormData.hasAboutMe === '1'} onChange={inputChangeHandler} /> Yes
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasAboutMe" value="0" checked={filterFormData.hasAboutMe === '0'} onChange={inputChangeHandler} /> No
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="hasLookingFor">Has Looking For</label>
            <div className='row'>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasLookingFor" value="" checked={filterFormData.hasLookingFor === ''} onChange={inputChangeHandler} /> All
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasLookingFor" value="1" checked={filterFormData.hasLookingFor === '1'} onChange={inputChangeHandler} /> Yes
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasLookingFor" value="0" checked={filterFormData.hasLookingFor === '0'} onChange={inputChangeHandler} /> No
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="hasActiveSubscription">Has Active Subscription</label>
            <div className='row'>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasActiveSubscription" value="" checked={filterFormData.hasActiveSubscription === ''} onChange={inputChangeHandler} /> All
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasActiveSubscription" value="1" checked={filterFormData.hasActiveSubscription === '1'} onChange={inputChangeHandler} /> Yes
                </label>
              </div>
              <div className='col-3'>
                <label>
                  <input type="radio" name="hasActiveSubscription" value="0" checked={filterFormData.hasActiveSubscription === '0'} onChange={inputChangeHandler} /> No
                </label>
              </div>
            </div>
          </div>

          <hr className='mt-4 mb-0' />
          <br />

          <div className="filter_ragSlider">
            <InputRange
              allowSameValues
              maxValue={SALARY_MAX_VALUE}
              minValue={SALARY_MIN_VALUE}
              step={SALARY_RANGE_STEP_VALUE}
              value={rangeValue}
              formatLabel={value => `$${value}K`}
              onChange={val => setRangeValue(val)}
              onChangeComplete={val => rangeUpdatedHandler(val)}
            />
            <label htmlFor="income_range" className='mt-4'>Income</label>
          </div>
          <div className="btn-group_wrapper">
            <button type="button" className="btn btn-theme" onClick={() => applyButtonHandler()}>Apply</button>
            <button type="button" className="btn btn-secondry" onClick={() => resetButtonHandler()}>Reset</button>
          </div>
        </div>
      </div>
    </>
  );
});

export default AdminSidebarFilter;
