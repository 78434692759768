import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment';
import { toast } from 'react-toastify';
// import heartIcon from '../assets/images/heart.svg';
import ProfileBox from '../components/general/profileBox';
import Spinner from '../components/general/spinner';
import webServices from '../services/webServices';
// import FreeTrailComponent from '../components/home/FreeTrailComponent';
import { getUserPlansDetails, setUser } from '../redux/actions/user.actions';
import { pageTitle } from '../redux/actions/home.actions';
import MatchesFilterPopup from './matchesFilterPopup';
import NoActivePlanPopup from './noActivePlanPopup';
import WelcomePopup from './welcomePopup';
import Section2remainderPopup from './section2remainderPopup';
import AppConstants from '../appConstants';
import PlanExpireRemainderPopup from './planExpireRemainderPopup';
import HoldMatchPopup from './holdMatchPopup';
import RoseSendPopup from './roseSendPopup';
import smilIcon from '../assets/images/smile-solid.svg';
import DailyLikeRemainderPopup from './dailyLikeRemainderPopup';
import DailyLikeRemainderForRosePopup from './dailyLikeRemainderForRosePopup';
import RoseExceedPopup from './roseExceedPopup';
import FirstMatchPopup from './firstMatchPopup';
import { authCall } from '../services';

const MatchesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { planDetails, current } = useSelector((state) => state.user);
  const [matchData, setMatchData] = useState([]);
  const [matchCount, setMatchCount] = useState(0);
  const [myMatchedCount, setMyMatchedCount] = useState(0);
  const [userData, setUserData] = useState({});
  const [isSpinner, setIsSpinner] = useState(true);
  const [bonusMsg, setBonusMsg] = useState(false);
  const [planExpireRemainder, setPlanExpireRemainder] = useState(false);
  const [dailyLikeRemainder, setDailyLikeRemainder] = useState(false);
  const [dailyLikeRemainderRose, setDailyLikeRemainderRose] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showActivePlan, setShowActivePlan] = useState(false);
  const [showRoseSend, setShowRoseSend] = useState(false);
  const [roseSent, setRoseSent] = useState(false);
  const [roseExceed, setRoseExceed] = useState(false);
  const [roseCountSend, setRoseCountSend] = useState(0);
  const [myTotalRoses, setMyTotalRoses] = useState(0);
  const [section2RemainderMsg, setSection2RemainderMsg] = useState(false);
  const [isOpenHoldMatch, setIsOpenHoldMatch] = useState(true);
  const [matchDataPageNo, setMatchDataPageNo] = useState(1);
  const [isOpenFirstMatchPopup, setIsOpenFirstMatchPopup] = useState(false);

  const checkIfUserPlanExpired = () => {
    if (current.plan_expiry_date === null) {
      return true;
    }

    const currentDate = moment().startOf('day');
    const planExpiryDate = moment(current.plan_expiry_date, 'YYYY-MM-DD');
    const days = Math.floor(moment.duration(planExpiryDate.diff(currentDate)).asDays());

    return days <= 0;
  };
  const isUserPlanExpired = checkIfUserPlanExpired();

  const showMatchQueuePreferencePage = () => {
    const todayDate = moment().format('YYYY-MM-DD');
    if (current.show_all_match_queue_users === null || current.show_all_match_queue_date !== todayDate) {
      return true;
    }
    return false;
  };

  const getMataches = async () => {
    const resp = await webServices.getPotentialMatches(matchDataPageNo);
    if (resp && resp.success === true) {
      setMatchData(resp.data);
      setUserData(resp.data[0]);

      setTimeout(() => {
        setIsSpinner(false);
      }, 1000);

      if (showMatchQueuePreferencePage() && resp.data.length === 0) {
        // history.push(AppConstants.routes.matchQueueCountryPreference);
      }
    }
  };

  const getMatchCount = async () => {
    const resp = await webServices.getMatchCount();
    if (resp && resp.success === true) {
      setMyMatchedCount(resp.data.count);
      // localStorage.setItem('matchCount', resp.data.count);
    }
  };

  // const handleShowActivePlan = () => setShowActivePlan(true);
  const handleCloseActivePlan = () => setShowActivePlan(false);
  const handleCloseBounsMsg = () => setBonusMsg(false);
  const handleCloseHoldMatch = () => setIsOpenHoldMatch(false);
  const handleClosePlanRemainder = () => setPlanExpireRemainder(false);

  const handleCloseCancel = () => {
    setRoseSent(false);
    setShowRoseSend(false);
  };

  const handleCloseRoseSend = () => {
    const count = matchCount >= matchData.length ? 0 : matchCount + 1;
    setMatchCount(count);
    setUserData(matchData[count]);
    setRoseSent(false);
    setShowRoseSend(false);
    if (count === matchData.length) {
      setMatchDataPageNo(pageNo => pageNo + 1);
      // getMataches();
    }
  };

  const handleCloseDailyLikeRemainder = () => setDailyLikeRemainder(false);
  const handleCloseDailyLikeRemainderRose = () => setDailyLikeRemainderRose(false);
  const handleCloseRoseExceed = () => setRoseExceed(false);
  const handleCloseSection2Remainder = () => setSection2RemainderMsg(false);
  const handleFirstMatchPopupCloseBtn = () => setIsOpenFirstMatchPopup(false);

  /*
  const handleLike = async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'like' });
    if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } else if (resp.data === 'dailyMatchNotAvaible') {
      getMataches();
    } else if (resp.data === 'likeAvailable') {
      const count = matchCount >= matchData.length ? 0 : matchCount + 1;
      setMatchCount(count);
      setUserData(matchData[count]);
      const uDetails = matchData[matchCount];
      if (uDetails?.userId) {
        await webServices.userLike({
          userId: uDetails?.userId,
          action: 'like'
        });
      }
      if (count === matchData.length) {
        getMataches();
      }
      getMatchCount();
      dispatch(getUserPlansDetails());
    } else if (resp.data === 'showDailyLikeRemainder') {
      setDailyLikeRemainder(true);
    }

    // else if (resp.data === 'profileNotApproved') {
    //   history.push(AppConstants.routes.congratulations);
    // } else if (resp.data === 'noActivePlan') {
    //   setDailyLikeRemainder(true);
    // } else {
    //   handleShowActivePlan(true);
    // }
  };

  const handleDislike = async () => {
    const count = matchCount >= matchData.length ? 0 : matchCount + 1;
    setMatchCount(count);
    setUserData(matchData[count]);
    const uDetails = matchData[matchCount];
    if (uDetails?.userId) {
      await webServices.userLike({
        userId: uDetails.userId,
        action: 'dislike'
      });
    }

    if (count === matchData.length) {
      getMataches();
    }
  };

  const handleSendRose = async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'rose' });
    if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } else if (resp.data === 'showDailyLikeRemainderRose') {
      dispatch(getUserPlansDetails());
      setDailyLikeRemainderRose(true);
    } else if (resp.data === 'showRosePurchase') {
      setShowRoseSend(true);
    } else {
      setShowRoseSend(true);
    }

    // if (resp.data === 'profileNotApproved') {
    //   history.push(AppConstants.routes.congratulations);
    // } else if (resp.data === 'showDailyLikeRemainder') {
    //   setDailyLikeRemainder(true);
    // }  else if (resp.data === 'noActivePlan') {
    //   handleShowActivePlan(true);
    // } else {
    //   setDailyLikeRemainder(true);
    // }
  };

  const handleConfirmRoseSend = async (response) => {
    if (response.roses) {
      const { data, success } = await webServices.getMyRoses();
      if ((success && data.my_roses < 1) || data.my_roses < response.roses) {
        setShowRoseSend(false);
        setRoseExceed(true);
        setRoseCountSend(response.roses);
        setMyTotalRoses(data.my_roses);
      } else {
        const count = matchCount >= matchData.length ? 0 : matchCount + 1;
        // setUserData(matchData[count]);
        const uDetails = matchData[matchCount];
        if (uDetails?.userId) {
          await webServices.userLike({
            userId: uDetails.userId,
            action: 'rose',
            roses: response.roses
          });
        } else {
          getMataches();
        }
        setRoseSent(true);
        dispatch(getUserPlansDetails());

        if (count === matchData.length) {
          getMataches();
        }
      }
    }
  };
  */

  const handleLike = async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'like' });
    if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } else if (resp.data === 'likeAvailable') {
      const count = matchCount >= matchData.length ? 0 : matchCount + 1;
      setMatchCount(count);
      setUserData(matchData[count]);
      const uDetails = matchData[matchCount];

      const apiResponse = await webServices.userLike({ userId: uDetails?.userId, action: 'like' });
      if (apiResponse?.data?.showFirstMatchPopup === true) {
        setIsOpenFirstMatchPopup(true);
      }

      if (count === matchData.length) {
        setMatchDataPageNo(pageNo => pageNo + 1);
        // getMataches();
      }
      getMatchCount();
      dispatch(getUserPlansDetails());
    } else if (resp.data === 'showDailyLikeRemainder') {
      setDailyLikeRemainder(true);
    } else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    }
  };

  const handleDislike = async () => {
    const resp = await authCall('user/userCheckPermission', {
      action: 'dislike'
    });
    if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } else if (resp.data === 'likeAvailable') {
      const count = matchCount >= matchData.length ? 0 : matchCount + 1;
      setMatchCount(count);
      setUserData(matchData[count]);
      const uDetails = matchData[matchCount];
      await webServices.userLike({
        userId: uDetails.userId,
        action: 'dislike'
      });
      if (count === matchData.length) {
        setMatchDataPageNo(pageNo => pageNo + 1);
        // getMataches();
      }
    } else if (resp.data === 'showDailyLikeRemainder') {
      setDailyLikeRemainder(true);
    } else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    }
  };

  const handleSendRose = async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'rose' });
    if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } else if (resp.data === 'showDailyLikeRemainder') {
      setDailyLikeRemainder(true);
    } else if (resp.data === 'showRosePurchase') {
      setShowRoseSend(true);
    } else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    } else {
      setShowRoseSend(true);
    }
  };

  const handleConfirmRoseSend = async (response) => {
    if (response.roses) {
      const { data, success } = await webServices.getMyRoses();
      if ((success && data.my_roses < 1) || data.my_roses < response.roses) {
        setShowRoseSend(false);
        setRoseExceed(true);
        setRoseCountSend(response.roses);
        setMyTotalRoses(data.my_roses);
      } else {
        const count = matchCount >= matchData.length ? 0 : matchCount + 1;
        setMatchCount(count);
        // setUserData(matchData[count]);
        const uDetails = matchData[matchCount];
        if (uDetails?.userId) {
          const apiResponse = await webServices.userLike({
            userId: uDetails.userId,
            action: 'rose',
            roses: response.roses
          });
          if (apiResponse?.data?.showFirstMatchPopup === true) {
            setIsOpenFirstMatchPopup(true);
          }
        } else {
          setMatchDataPageNo(pageNo => pageNo + 1);
          // getMataches();
        }
        setRoseSent(true);
        dispatch(getUserPlansDetails());

        if (count === matchData.length) {
          setMatchDataPageNo(pageNo => pageNo + 1);
          // getMataches();
        }
      }
    }
  };

  useEffect(async () => {
    getMataches();
    setMatchCount(0);
  }, [matchDataPageNo]);

  useEffect(async () => {
    dispatch(pageTitle('Match Queue'));
    getMataches();
    getMatchCount();
    const resp = await webServices.getUserDetail();
    if (resp && resp.success === true) {
      dispatch(setUser(resp.data));
    }
  }, []);

  useEffect(() => {
    dispatch(getUserPlansDetails());
    setBonusMsg(planDetails.isReadBonusMsg);

    if (current.plan_subscription_id === null) {
      setPlanExpireRemainder(planDetails.isReadPlanRemainderMsg);
    }
  }, [
    dispatch,
    planDetails.isShowTrial,
    planDetails.isReadBonusMsg,
    planDetails.isReadPlanRemainderMsg
  ]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const callBackFunction = async (values) => {
    toast.dismiss();
    setLoader(true);
    const stat = await webServices.updateUserSettings(values);
    if (stat?.success) {
      setShow(false);
      toast.success('Filter updated successfully');
      setMatchDataPageNo(1);
      getMataches();
      setLoader(false);
    }
  };

  const filterParentCallBack = async (status) => {
    setShow(status);
  };

  const handleClickProfile = (val) => {
    history.push({
      pathname: AppConstants.routes.publicProfile,
      state: { occordian: val }
    });
  };

  return (
    <>
      <section
        className={`inner-wrapper-newdesign mobilematchQue ${matchData.length > 0 || isSpinner
            ? ''
            : `matchNotFound ${current.looking_for === 'WOMAN' ? 'womanFound' : 'manFound'
            }`
          }`}
      >
        <div className="row">
          <div className="col-md-12 ">
            <div className={isSpinner ? 'row spinner-row' : 'largeScreen'}>
              <>
                {isSpinner ? (
                  <>
                    <Spinner active={isSpinner} />
                  </>
                ) : (
                  <>
                    <div
                      className="col-md-12 filters-line cursor-pointer d-none"
                      onClick={handleShow}
                      aria-hidden="true"
                    >
                      <span>
                        <i className="fa fa-sliders" aria-hidden="true" />
                        Filter
                      </span>
                    </div>

                    {
                      // eslint-disable-next-line no-nested-ternary
                      matchData.length > 0 ? (
                        <>
                          {userData && (
                            <ProfileBox
                              data={userData}
                              handleLike={handleLike}
                              handleDislike={handleDislike}
                              handleSendRose={handleSendRose}
                              matchCount={myMatchedCount}
                              filterParentCallBack={filterParentCallBack}
                              // handleShowActivePlan={handleShowActivePlan}
                              current={current}
                              isUserPlanExpired={isUserPlanExpired}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <div className="no-found-wrapper">
                            <div className="title">
                              <h1>Love is on the Way!</h1>
                              <p>
                                No new matches yet. We are delighted you are here
                                and we can’t <br />
                                wait for you to meet the love of your life!{' '}
                              </p>
                              <p>
                                In the meantime, take a few minutes to spice up
                                your profile <br />
                                with the three steps below. It is the best way to
                                up your <br />
                                odds of getting a match. It is also a nice way to
                                show your <br />
                                matches that you care and they’ll appreciate that
                                about <br />
                                you...and your mama will be proud that you took
                                her <br />
                                “make a good first impression” lesson to heart.{' '}
                                <img
                                  src={smilIcon}
                                  className="smaill-img"
                                  alt="smile-img"
                                />
                              </p>
                              <ul className="list-unstyled pt-2">
                                <li>
                                  <i
                                    className={`fa fa-check ${current?.images &&
                                      current?.images.length > 5 &&
                                      'active'
                                      }`}
                                    aria-hidden="true"
                                  />
                                  <button
                                    type="button"
                                    className="btn addPhoto"
                                    onClick={() => handleClickProfile('3')}
                                  >
                                    Add Photos
                                  </button>
                                </li>
                                <li>
                                  <i
                                    className={`fa fa-check ${current.hobbies && 'active'
                                      }`}
                                    aria-hidden="true"
                                  />
                                  <button
                                    type="button"
                                    className="btn addHobies"
                                    onClick={() => handleClickProfile('0')}
                                  >
                                    Add Hobbies
                                  </button>
                                </li>
                                <li>
                                  <i
                                    className={`fa fa-check ${current.anual_income && 'active'
                                      }`}
                                    aria-hidden="true"
                                  />
                                  <button
                                    type="button"
                                    className="btn updateProfile"
                                    onClick={() => handleClickProfile('0')}
                                  >
                                    Update Profile
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </section>

      <MatchesFilterPopup
        modalStatus={show}
        handleClose={handleClose}
        parentCallBack={callBackFunction}
        // parentGetSettingByCallBack={getSettingByCallBack}
        loaderStatus={loader}
      />

      <NoActivePlanPopup
        modalStatus={showActivePlan}
        handleClose={handleCloseActivePlan}
      // currentPlan={planDetails}
      // userDetails={current}
      />

      <Section2remainderPopup
        modalStatus={section2RemainderMsg}
        handleClose={handleCloseSection2Remainder}
        userDetails={current}
      />

      {!isSpinner && (
        <WelcomePopup
          modalStatus={bonusMsg}
          handleClose={handleCloseBounsMsg}
          userDetails={current}
        />
      )}

      <PlanExpireRemainderPopup
        modalStatus={planExpireRemainder}
        currentPlan={planDetails}
        userDetails={current}
        handleClose={handleClosePlanRemainder}
      />

      <HoldMatchPopup
        modalStatus={planDetails.isHoldMatches === '1' && isOpenHoldMatch}
        handleClose={handleCloseBounsMsg}
        handleCloseHoldMatch={handleCloseHoldMatch}
        userDetails={current}
      />

      <RoseSendPopup
        modalStatus={showRoseSend}
        handleClose={handleCloseRoseSend}
        userData={userData}
        handleConfirmRoseSend={handleConfirmRoseSend}
        roseSent={roseSent}
        handleCloseCancel={handleCloseCancel}
      />

      <DailyLikeRemainderPopup
        modalStatus={dailyLikeRemainder}
        currentPlan={planDetails}
        userDetails={current}
        handleClose={handleCloseDailyLikeRemainder}
      />

      <RoseExceedPopup
        modalStatus={roseExceed}
        userData={userData}
        // userDetails={current}
        roseCountSend={roseCountSend}
        myTotalRoses={myTotalRoses}
        handleClose={handleCloseRoseExceed}
      />

      <DailyLikeRemainderForRosePopup
        modalStatus={dailyLikeRemainderRose}
        currentPlan={planDetails}
        userDetails={current}
        handleClose={handleCloseDailyLikeRemainderRose}
      />

      <FirstMatchPopup
        modalStatus={isOpenFirstMatchPopup}
        handleClose={handleFirstMatchPopupCloseBtn}
        userDetails={current}
      />
    </>
  );
};

export default MatchesPage;
